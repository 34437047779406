import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  return '/'
}


/**
 * Check is user have permission.
 * 
 * @param String permission 
 * 
 * @returns boolean
 */
export const checkPermission = permission => {
  if (permission == null) {
    return true;
  } else {
    const userData = JSON.parse(localStorage.getItem('userData'))

    return userData.permissions.includes(permission)
  }
}

/**
 * 
 * Standard success notification.
 * 
 * @param {*} context This Context 
 * @param String title 
 * @param String message 
 */
export const successNotification = (context, title, message) => {
  context.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: title,
      icon: 'CheckIcon',
      variant: 'success',
      text: message,
    },
  })
}

/**
 * 
 * Standard error notification.
 * 
 * @param {*} context This Context 
 * @param String title 
 * @param String|Array message 
 */
 export const errorNotification = (context, title, message) => {
    if (Array.isArray(message)) {
      message.forEach(function(item){
        context.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: title,
            icon: 'DangerIcon',
            variant: 'danger',
            text: item,
          },
        })
      });
    } else {
      context.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: title,
          icon: 'DangerIcon',
          variant: 'danger',
          text: message,
        },
      })
    }
}

export const formatRupiah = (angka, prefix) => {
  const number_string = angka.replace(/[^.\d]/g, '').toString()
  const split = number_string.split('.')
  const sisa = split[0].length % 3
  let currency = split[0].substr(0, sisa)
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi)
  if (ribuan) {
    const separator = sisa ? ',' : ''
    currency += separator + ribuan.join(',')
  }
  currency = split[1] != undefined ? `${currency}.${split[1]}` : currency
  return prefix == undefined ? currency : (currency || '')
}

export const preformatFloat = (float) => {
  if (!float) {
    return ''
  }
  // Index of first comma
  const posC = float.indexOf(',')
  if (posC === -1) {
    // No commas found, treat as float
    return float.replace(/\,/g, '')
  }
  // Index of first full stop
  const posFS = float.indexOf('.')
  if (posFS === -1) {
    // Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
    return float.replace(/\,/g, '')
  }
  // Uses both commas and full stops - ensure correct order and remove 1000s separators
  return ((posC < posFS) ? (float.replace(/\,/g, '')) : (float.replace(/\,/g, '').replace('.', ',')))
}