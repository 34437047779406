import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        guest: true,
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        auth: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auctions',
      name: 'auctions.index',
      component: () => import('@/views/auction/AuctionManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Auctions',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auctions-sob',
      name: 'auctions.sob',
      component: () => import('@/views/auction/SobManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Auction SOB',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'SOB',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auction-detail/:uuid',
      name: 'auctions.show',
      component: () => import('@/views/auction/DetailAuction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Detail Auction',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        navActiveLink: 'auctions.index',
      },
    },
    {
      path: '/auctions-transactions',
      name: 'auctions.transactions.index',
      component: () => import('@/views/auction/ListAuctionTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Transaction',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/block-trade',
      name: 'block-trade.index',
      component: () => import('@/views/auction/BlockTradeManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Block Trade',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Block Trade List',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-auction-transaction',
      name: 'auctions.transactions.add',
      component: () => import('@/views/auction/AddAuctionTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Add Transaction',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auctions-match',
      name: 'auctions.transactions.match',
      component: () => import('@/views/auction/ListAuctionMatchTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Match',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'Match',
            active: true,
          },
        ],
      },
    },
    {
      path: '/trade-allocations',
      name: 'auctions.transactions.trade-allocations',
      component: () => import('@/views/auction/ListTradeAllocation.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Trade Allocation',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'Trade Allocation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transactions-settlement',
      name: 'auctions.transactions.pending-settlement',
      component: () => import('@/views/auction/ListAuctionTransactionPendingSettlement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Transaction',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'Pending Settlement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auctions-transaction-detail/:uuid',
      name: 'auctions.transactions.show',
      component: () => import('@/views/auction/DetailAuctionTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Detail Transaction',
        breadcrumb: [
          {
            text: 'Auction',
            active: true,
          },
          {
            text: 'Transaction',
            active: true,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        navActiveLink: 'auctions.transactions.index',
      },
    },
    {
      path: '/commodities',
      name: 'commodities.index',
      component: () => import('@/views/commodity/CommodityManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Commodities',
        breadcrumb: [
          {
            text: 'Commodity',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commodity-transactions',
      name: 'commodity-transactions.index',
      component: () => import('@/views/commodity/ListCommodityTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Commodity Transaction',
        breadcrumb: [
          {
            text: 'Commodity',
            active: true,
          },
          {
            text: 'Transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/collateral-type',
      name: 'collateral-type.index',
      component: () => import('@/views/cash/CollateralTypeManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Collateral Type',
        breadcrumb: [
          {
            text: 'Collateral',
            active: true,
          },
          {
            text: 'Type',
            active: true,
          },
        ],
      },
    },
    {
      path: '/currency',
      name: 'currency.index',
      component: () => import('@/views/currency/CurrencyManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Currency',
        breadcrumb: [
          {
            text: 'Currency',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cash-transactions',
      name: 'cash-transactions.index',
      component: () => import('@/views/cash/ListCashTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Cash Transaction',
        breadcrumb: [
          {
            text: 'Cash Balance',
            active: true,
          },
          {
            text: 'Transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cash-adjustment-transactions',
      name: 'cash-adjustment-transactions.index',
      component: () => import('@/views/cash/ListCashAdjustmentTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Cash Adjustment',
        breadcrumb: [
          {
            text: 'Cash Adjustment',
            active: true,
          },
          {
            text: 'Transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users.index',
      component: () => import('@/views/user/UserManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Users',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-detail/:id',
      name: 'users.show',
      component: () => import('@/views/user/DetailUser.vue'),
      meta: {
        auth: true,
        pageTitle: 'Detail User',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        navActiveLink: 'users.index',
      },
    },
    {
      path: '/topup',
      name: 'topup.index',
      component: () => import('@/views/cash/TopupManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Topup Cash',
        breadcrumb: [
          {
            text: 'Topup',
            active: true,
          },
          {
            text: 'Cash',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/topup-collateral',
      name: 'topup-collateral.index',
      component: () => import('@/views/cash/TopupCollateralManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Topup Collateral',
        breadcrumb: [
          {
            text: 'Topup',
            active: true,
          },
          {
            text: 'Collateral',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-bank',
      name: 'payment-bank.index',
      component: () => import('@/views/cash/PaymentBankManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Payment Bank',
        breadcrumb: [
          {
            text: 'Payment Bank Account',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-bank',
      name: 'user-bank.index',
      component: () => import('@/views/cash/UserBankManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List User Bank',
        breadcrumb: [
          {
            text: 'Withdraw',
            active: true,
          },
          {
            text: 'User Bank Account',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/warehouse',
      name: 'warehouse.index',
      component: () => import('@/views/commodity/WarehouseManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Depository Management',
        breadcrumb: [
          {
            text: 'Depository Management',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdraw',
      name: 'withdraw.index',
      component: () => import('@/views/cash/WithdrawManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Withdraw',
        breadcrumb: [
          {
            text: 'Withdraw',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commodity-withdraw',
      name: 'commodity-withdraw.index',
      component: () => import('@/views/commodity/CommodityWithdrawManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Commodity Withdraw',
        breadcrumb: [
          {
            text: 'Commodity',
            active: true,
          },
          {
            text: 'Withdraw',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/holiday',
      name: 'holiday.index',
      component: () => import('@/views/setting/HolidayManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Holiday Management',
        breadcrumb: [
          {
            text: 'Holiday',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity-log',
      name: 'activity-log.index',
      component: () => import('@/views/user/ActivityLog.vue'),
      meta: {
        auth: true,
        pageTitle: 'User Activity Log',
        breadcrumb: [
          {
            text: 'Activity Log',
            active: true,
          },
        ],
      },
    },
    {
      path: '/role',
      name: 'role.index',
      component: () => import('@/views/setting/RoleManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Role Management',
        breadcrumb: [
          {
            text: 'Role',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin',
      name: 'admin.index',
      component: () => import('@/views/admin/AdminManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'List Admin',
        breadcrumb: [
          {
            text: 'Admin',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commodity-category',
      name: 'commodity-category.index',
      component: () => import('@/views/commodity/CategoryManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Commodity Category Management',
        breadcrumb: [
          {
            text: 'Commodity Category',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/setting',
      name: 'setting.index',
      component: () => import('@/views/setting/SettingPage.vue'),
      meta: {
        auth: true,
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Setting',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news',
      name: 'news.index',
      component: () => import('@/views/content/NewsManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'News',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/page',
      name: 'page.index',
      component: () => import('@/views/content/PageManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Page',
        breadcrumb: [
          {
            text: 'Page',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rules',
      name: 'rules.index',
      component: () => import('@/views/content/RuleManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Rule',
        breadcrumb: [
          {
            text: 'Rule',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/information',
      name: 'information.index',
      component: () => import('@/views/content/InformationManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Information',
        breadcrumb: [
          {
            text: 'Information',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faq',
      name: 'faq.index',
      component: () => import('@/views/content/FAQManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'FAQ',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/image',
      name: 'image.index',
      component: () => import('@/views/content/ImageManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Meta Image',
        breadcrumb: [
          {
            text: 'Meta Image',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/text',
      name: 'text.index',
      component: () => import('@/views/content/TextManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Meta Text',
        breadcrumb: [
          {
            text: 'Meta Text',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/partner',
      name: 'partner.index',
      component: () => import('@/views/content/PartnerManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Partner',
        breadcrumb: [
          {
            text: 'Partner',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sliders',
      name: 'slider.index',
      component: () => import('@/views/content/SliderManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Slider',
        breadcrumb: [
          {
            text: 'Slider',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/join-now-images',
      name: 'join-now-images.index',
      component: () => import('@/views/content/JoinNowImageManagement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Join Now Images',
        breadcrumb: [
          {
            text: 'Join Now Images',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/setting/Profile.vue'),
      meta: {
        auth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/cash-transfer',
      name: 'report.cash-transfer',
      component: () => import('@/views/report/CashTransfer.vue'),
      meta: {
        auth: true,
        pageTitle: 'Report Cash Transfer',
        meta: {
          auth: true,
          pageTitle: 'Settlement Price Report',
          breadcrumb: [
            {
              text: 'Report',
              active: false,
            },
            {
              text: 'Cash Transfer',
              active: true,
            },
          ],
        },
      },
    },
    {
      path: '/report/settlement-price',
      name: 'report.settlement-price',
      component: () => import('@/views/report/SettlementPrice.vue'),
      meta: {
        auth: true,
        pageTitle: 'Settlement Price Report',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Settlement Price',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/fee-transaction',
      name: 'report.fee-transaction',
      component: () => import('@/views/report/FeeTransaction.vue'),
      meta: {
        auth: true,
        pageTitle: 'Report Fee Transaction',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Fee Transaction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/outstanding-delivery',
      name: 'report.outstanding-delivery',
      component: () => import('@/views/report/OutstandingDelivery.vue'),
      meta: {
        auth: true,
        pageTitle: 'Outstanding Delivery Report',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Oustanding Delivery',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/commodity-stock',
      name: 'report.commodity-stock',
      component: () => import('@/views/report/CommodityStock.vue'),
      meta: {
        auth: true,
        pageTitle: 'Report Commodity Stock',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Commodity Stock',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/recap-clearing',
      name: 'report.recap-clearing',
      component: () => import('@/views/report/RecapClearing.vue'),
      meta: {
        auth: true,
        pageTitle: 'Report Recap Clearing',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Recap Clearing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/clearing-statement',
      name: 'report.clearing-statement',
      component: () => import('@/views/report/ClearingStatement.vue'),
      meta: {
        auth: true,
        pageTitle: 'Clearing Statement',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Settlement Price',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/transaction-history',
      name: 'report.transaction-history',
      component: () => import('@/views/report/TransactionHistory.vue'),
      meta: {
        auth: true,
        pageTitle: 'Transaction History Report',
        breadcrumb: [
          {
            text: 'Report',
            active: false,
          },
          {
            text: 'Transaction History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next();
    }
  } else {
    next();
  }

  // Redirect if authenticated
  if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }

});

export default router
